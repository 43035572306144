@import "../../custom";

.body {
  background-color: map-get($spirii-theme-colors, "background");
  width: 100%;
  height: 100%;
  padding: 0;
}

.title {
  display: flex;
  font-size: 28px !important; // Was 32px
  color: map-get($spirii-theme-colors, "text");
  font-weight: 700 !important;
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 64px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
