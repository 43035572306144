@import "../../custom";

.header {
  font-size: 16px;
  color: map-get($spirii-theme-colors, "text");
  font-weight: 700;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  border-bottom: 1px dotted gray;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.internal {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
}
