@import "../../custom";

.marker {
  background-color: map-get($spirii-theme-colors, "accent-color-1");
  border: 1px solid white;
  border-radius: 6px;
  color: map-get($spirii-theme-colors, "text-contrast");
  font: bold 16px Helvetica, Verdana, Tahoma;
  height: 24px;
  min-width: 14px;
  padding: 3px 3px 0 3px;
  text-align: center;
}
