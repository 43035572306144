@import "../../custom";

.loadingBall1 {
  position: absolute;
  color: map-get($spirii-theme-colors, "accent-color-1");
}

.loadingBall2 {
  position: absolute;
  color: map-get($spirii-theme-colors, "accent-color-2");
}

.loadingBall3 {
  position: absolute;
  color: map-get($spirii-theme-colors, "accent-color-3");
}
