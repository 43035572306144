@import "../../custom";

.growth {
  display: flex;
  flex-direction: column;
}

.label {
  align-self: center;
  font-size: large;
  color: map-get($spirii-theme-colors, "text");
  line-height: normal;
}

.data {
  align-self: center;
  font-size: 48px;
  color: map-get($spirii-theme-colors, "text");
  font-weight: 500;
  display: flex;
  flex-direction: row;
  line-height: normal;
}
