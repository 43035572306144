@import "../../custom";

.update {
  text-align: start;
  font-size: 32px;
  color: map-get($spirii-theme-colors, "text");
  font-weight: 500;
}

.row {
  line-height: 40px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
