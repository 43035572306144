@import "../../custom";

.body {
  background-color: map-get($spirii-theme-colors, "background");
  width: 100%;
  height: 100%;
  padding: 0;
}

.title {
  display: flex;
  font-size: 32px !important;
  color: map-get($spirii-theme-colors, "text");
  font-weight: 700 !important;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
