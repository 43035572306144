@import "../../custom";

.header {
  height: 75px;
  border-bottom: 3px solid map-get($spirii-theme-colors, "border");
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.label {
  padding-left: 50px;
  font-size: 36px;
  font-weight: 700;
  color: map-get($spirii-theme-colors, "text-contrast");
}

.logo {
  // TODO: Fix fax
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}

.image {
  height: 60px;
  padding-right: 50px;
}
