$body-bg: #343a40;

// Colors for bootstrap
$theme-colors: (
        "primary": #494c4d,
        "secondary": #6c757d,
        "warning": #ffc107,
        "danger": #dc3545,
        "success": #198754,
        "info": #0dcaf0,
        "light": #f8f9fa,
        "dark": #212529
);

// Used in our own styling
$spirii-theme-colors: (
        "text": #494c4d,
        "background": white, // #fffdfa,
        "border": #717577,
        "text-contrast": white,
        "accent-color-1": #635779,
        "accent-color-2": #b59148,
        "accent-color-3": #d9e796,
);
